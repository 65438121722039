<template id="splash-one">
    <div class="splash-wrapper">
    <KlinkLoader/>
        <div class="splash-bckg">
            <div class="splash-content">

                <SplashScreenOne v-if="splashScreenPhase === 1" 
                            :user-display-name="userDisplayName" 
                            :user-image-src="userImageSrc"/>
                <SplashScreenTwo v-if="splashScreenPhase === 2" 
                            :user-display-name="userDisplayName" 
                            :company-avatar="companyAvatar"/>
            </div>
        </div>
    </div>
</template>
<script>
import { KlinkLoader } from 'klink-frontend-toolkit-vue';
import SplashScreenOne from './SplashScreenOne.vue';
import SplashScreenTwo from './SplashScreenTwo.vue';

    export default {
        name: 'splash-screen',
        data(){
            return{
                companyAvatar: "",
                userDisplayName: "",
                userImageSrc: "",
            }
        },
        components:{
            KlinkLoader,
            SplashScreenOne,
            SplashScreenTwo,
        },
        data(){
            return{
                splashScreenPhase: 1, //1 is the default start
            }
        },
        methods:{
            nextSplashScreenPhase(timeout){
                this.splashScreenPhase++;
            },
            endSplashScreen(){
                this.splashScreenPhase = 0; //Phase 0 is finish
            },
        },
        created(){
            var eventApLoaded = new CustomEvent("appLoaded");
            this.userDisplayName = localStorage.getItem("user_display_name");
            this.userImageSrc = localStorage.getItem("user_image");
            this.companyAvatar = require('@/assets/img/Logoanimasjon_loop.gif')
            setTimeout(() => {
                setTimeout(() => {
                    this.splashScreenPhase = 0;
                    localStorage.setItem('appLoaded', 'true');
                    window.dispatchEvent(eventApLoaded);
                    document.body.classList.replace('splash-loading', 'splash-loaded')
                }, 400);
                this.nextSplashScreenPhase();
            }, 400);
        },
    }
</script>