import * as Msal from 'msal';
import { initApp } from "../main";
var appSettings = require("./appSettings.json");
const signalR = require("@aspnet/signalr");
var config = require("./config.json");

var helpers = {
  done: false,
  callMSGraph: function (theUrl, accessToken, callback) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      var self = this;
      if (xmlHttp.readyState === 4 && !self.done) {
        self.done = true;
        callback(this.response);
      }
    }
    xmlHttp.responseType = 'blob';
    xmlHttp.open("GET", theUrl, true); // true for asynchronous
    xmlHttp.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xmlHttp.send();
  },
  //The place for loading single-spa logic
  graphAPICallback: function (data) {
    var binaryData = [];
    binaryData.push(data);
    const base64Str = window.URL.createObjectURL(new Blob(binaryData, { type: "image/png" }));

    var img = new Image();
    img.src = base64Str;

    //Make it fake for testing purposes

    img.onload = function () {
      //Here do not do nothing
      localStorage.setItem("user_image", base64Str);
    }

    img.onerror = function () {
      //Here be logic for handling default image
      serveDefaultImage();
    }

    var user = userAgentApplication.getAccount();
    localStorage.setItem("user_id", user.idToken.oid);
    localStorage.setItem("user_display_name", user.idToken.name);
    localStorage.setItem("user_name", user.idToken.preferred_username);
    if ("tid" in user.idToken) {
      localStorage.setItem("tenant_id", user.idToken.tid);
    }
    else {
      var tid = user.idToken.iss.replace("https://login.microsoftonline.com/", "");
      tid = tid.substring(0, 36);
      localStorage.setItem("tenant_id", tid);
    }

    //Call single-spa initialization
    initApp();
  }
}

//Azure AD authentication flow
//Instantiate app config

// New msal version is initialized with a seperate config object,
// so we don't use the applicationConfig object anymore,
// but a seperate msalConfig object.
// applicationConfig contains configs which are not part of msalConfig, but still needed for auth
var applicationConfig = {
  graphEndpoint: config.active_env.url_graph + "me/photo/$value",
  scopesObj: { scopes: ["user.read"] }
};
var msalConfig = {
  auth: {
    clientId: appSettings.OpenIdConnect.ClientId,
    authority: appSettings.OpenIdConnect.Authority,
    redirectUri: appSettings.OpenIdConnect.CallbackPath,
    postLogoutRedirectUri: appSettings.OpenIdConnect.PostLogoutRedirectUri
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true // added for IE and Edge support
  }
};

//Initialize msal application
var userAgentApplication = new Msal.UserAgentApplication(msalConfig);

// Required handleRedirectCallback function
var authCallback = function (error, response) {
  if (error) {
    console.log("authCallback returned error: ", error);
  }
  else if (response) {
    console.log("authCallback returned response: ", response);
  }
}
userAgentApplication.handleRedirectCallback(authCallback);

window.userAgentApplication = userAgentApplication;

//If user doesn't exists it means there is no logged in user,
//so must deny access to app and redirect to login form
var user = userAgentApplication.getAccount();

let isCypressLogin = localStorage.getItem("cypressLogin") ? localStorage.getItem("cypressLogin") : "false";

if (!user && !userAgentApplication.isCallback(window.location.hash)) {
  if (window.location.hash.indexOf("#") !== -1) {
    window.location.replace("/");
  }
  else if (isCypressLogin === "true") {
    initApp();
  }
  else {
    userAgentApplication.loginRedirect(applicationConfig.scopesObj);
  }
}


//In case user is logged in and url doesn't contain hash with user token
//Aquire token and fetch user metadata from msgraph
if (user && !userAgentApplication.isCallback(window.location.hash)) {// avoid duplicate code execution on page load in case of iframe and popup window.
  acquireTokenRedirectAndCallMSGraph();
}

//fetch user token and metada data from microsoft graph API
function acquireTokenRedirectAndCallMSGraph() {
  //Call acquireTokenSilent (iframe) to obtain a token for Microsoft Graph
  userAgentApplication.acquireTokenSilent(applicationConfig.scopesObj, msalConfig.auth.authority).then(function (response) {
    localStorage.setItem("user_token", response.accessToken);
    helpers.callMSGraph(applicationConfig.graphEndpoint, response.accessToken, helpers.graphAPICallback);
    var parsedToken = parseJwt(localStorage.user_token);
    checkTokenRefresh(parsedToken.exp)
  }, function (error) {
    //Call acquireTokenRedirect in case of acquireToken from cache failed
    if (error.errorCode.indexOf("consent_required") !== -1 || error.errorCode.indexOf("interaction_required") !== -1 || error.errorCode.indexOf("login_required") !== -1 || error.errorCode.indexOf("token_renewal_error") !== -1 || error.errorCode.indexOf("multiple_authorities") !== -1) {
      userAgentApplication.acquireTokenRedirect(applicationConfig.scopesObj);
    }
  }).catch(function (error) {
    userAgentApplication.acquireTokenRedirect(applicationConfig.scopesObj);
  })
}

function serveDefaultImage() {
  var usrImg = require('../assets/img/user-image-default.png');
  localStorage.setItem("user_image", usrImg);
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
function checkTokenRefresh(token_expiry) {
  var tokenRefreshInterval = setInterval(function () {
    if (token_expiry === Math.floor(Date.now() / 1000)) {
      window.postMessage({
        event_id: 'fireNotification',
        params: {
          type: 'information',
          text: 'Session expired. Fetching new access token!'
        }
      },
        "*");
      setTimeout(() => {
        clearInterval(tokenRefreshInterval);
        acquireTokenRedirectAndCallMSGraph();
      }, 2000);
    }
  }, 1000);
}