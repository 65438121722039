// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "zone.js";
import * as singleSpa from "single-spa";
import { GlobalEventDistributor } from "./helpers/globalEventDistributor";
import { loadApp } from "./helpers/helper";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import httpMixin from "./helpers/mixins/http.js";
import nav from "./helpers/nav";
import VueGtag from "vue-gtag";
import VueGtm from "@garyo/vue-gtm";
import SplashScreen from '../src/components/widgets/SplashScreen.vue';

// Bootstrap parts import
import { Dropdown } from 'bootstrap';
import { Modal } from 'bootstrap';

var appSettings = require("./helpers/appSettings.json");
var config = require("./helpers/config.json");

//SingleSpa
async function initSingleSpa() {
  const globalEventDistributor = new GlobalEventDistributor();
  const loadingPromises = [];

  let mountedAppNames = singleSpa.getMountedApps();
  let appNames = singleSpa.getAppNames();

  // devices: The URL "/devices/..." is being redirected to "http://localhost:9004/..." this is done by the webpack proxy (webpack.config.js)
  if (!mountedAppNames.includes("devices") && !appNames.includes("devices")) {
    loadingPromises.push(
      loadApp(
        "devices",
        "/devices",
        "/devices/js/app.js",
        null,
        globalEventDistributor
      )
    );
  }

  // statistics: The URL "/statistics/..." is being redirected to "http://localhost:9008/..." this is done by the webpack proxy (webpack.config.js)
  // if (!mountedAppNames.includes("statistics") && !appNames.includes("statistics")) {
  //   loadingPromises.push(
  //     loadApp(
  //       "statistics",
  //       "/statistics",
  //       "/statistics/singleSpaEntry.js",
  //       "/statistics/store.js",
  //       globalEventDistributor
  //     )
  //   );
  // }

  // infoboard: The URL "/infoboard/..." is being redirected to "http://localhost:9005/..." this is done by the webpack proxy (webpack.config.js)
  if (!mountedAppNames.includes("infoboard") && !appNames.includes("infoboard")) {
    loadingPromises.push(
      loadApp(
        "infoboard",
        "/infoboard",
        "/infoboard/js/app.js",
        // "/infoboard/store.js",
        null,
        globalEventDistributor
      )
    );
  }

  // alerting: The URL "/alerting/..." is being redirected to "http://localhost:9007/..." this is done by the webpack proxy (webpack.config.js)
  // if (!mountedAppNames.includes("alerting") && !appNames.includes("alerting")) {
  //   loadingPromises.push(
  //     loadApp(
  //       "alerting",
  //       "/alerting",
  //       "/alerting/singleSpaEntry.js",
  //       "/alerting/store.js",
  //       globalEventDistributor
  //     )
  //   );
  // }

  // map: The URL "/map/..." is being redirected to "http://localhost:9006/..." this is done by the webpack proxy (webpack.config.js)
  if (!mountedAppNames.includes("map") && !appNames.includes("map")) {
    loadingPromises.push(
      loadApp(
        "map",
        "/map",
        "/map/js/app.js",
        null,
        globalEventDistributor
      )
    );
  }

  // settings: The URL "/settings/..." is being redirected to "http://localhost:9009/..." this is done by the webpack proxy (webpack.config.js)
  if (!mountedAppNames.includes("settings") && !appNames.includes("settings")) {
    loadingPromises.push(
      loadApp(
        "settings",
        "/settings",
        "/settings/js/app.js",
        null,
        globalEventDistributor
      )
    );
  }

  // datasources: The URL "/datasources/..." is being redirected to "http://localhost:9010/..." this is done by the webpack proxy (webpack.config.js)
  if (!mountedAppNames.includes("datasources") && !appNames.includes("datasources")) {
    loadingPromises.push(
      loadApp(
        "datasources",
        "/datasources",
        "/datasources/js/app.js",
        null,
        globalEventDistributor
      )
    );
  }

  // dataimporter: The URL "/dataimporter/..." is being redirected to "http://localhost:9011/..." this is done by the webpack proxy (webpack.config.js)
  if (!mountedAppNames.includes("dataimporter") && !appNames.includes("dataimporter")) {
    loadingPromises.push(
      loadApp(
        "dataimporter",
        "/dataimporter",
        "/dataimporter/js/app.js",
        null,
        globalEventDistributor
      )
    );
  }

  // wait until all stores are loaded and all apps are registered with singleSpa
  await Promise.all(loadingPromises).then(() => { 
    singleSpa.start();
  });
}

function initFeatures() {
  var appId = appSettings.OpenIdConnect.ClientId;
  var options = {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      AppId: appId,
      Authorization: "Bearer " + localStorage.getItem("user_token")
    },
    method: "GET",
    url: config.active_env.url_feature_toggle + "featuresconsul"
  };
  axios(options)
    .then((response) => {
      let responseData = response.data;
      let tenantInfo;
      let featureData;

      if (responseData) {
        tenantInfo = responseData.find(item => item.rowKey === "metadata");
        featureData =  responseData.filter(item => item.rowKey !== "metadata");

        if (tenantInfo) {
          localStorage.setItem("tenant_name", tenantInfo.tenantName);
          localStorage.setItem("tenant_domain", tenantInfo.domain);
        }

        if (featureData && featureData.length > 1) {
          nav.initializeFeatureToggling(featureData);
          initVue();
        } else {
          nav.setDefaults();
          initVue();
        }
        initSingleSpa(); // This was line below after if, not know why, if there is problems return it!
      }
    })
    .catch((error) => {
      nav.setDefaults();
      initVue();
      initSingleSpa();
    });
}

function saveConfigToLocalStorage() {
  localStorage.activeAppConfig = JSON.stringify(config.active_env);
}

function notifyLogin(vm) {
  var activeConfig = JSON.parse(localStorage.activeAppConfig);
  var url = activeConfig.url_user_activity_tracking + "activitytracker";
  var UATMessage = { MessageId: "notify_login" }
  var options = {
    headers: {
      "Content-type": "application/json;charset=UTF-8",
      Accept: "application/json;charset=UTF-8",
      "X-Correlation-Id": localStorage.user_id
    },
    method: "POST",
    url: url,
    body: JSON.stringify(UATMessage),
    auth: true
  };
  vm.sendRequest(options)
    .then(function (response) {
      console.log("Notify Login RESPONSE: " + response.data);
    })
    .catch(function (err) {
      console.log("Notify Login ERROR");
      console.log(err);
    });
}

function initVue() {
  Vue.use(VueGtag, {
    config: { id: process.env.GOOGLE_ANALYTICS_ID }
  });

  Vue.use(VueGtm, { 
    id: process.env.GOOGLE_TAG_MANAGER_ID
  });

  //Vue instantiation
  var vm = new Vue({
    el: "#app",
    mixins: [httpMixin],
    render: h => h(App),
    components: { App },
    router,
    methods: {
      toggleMenu() {
        document.body.className.indexOf("sidebar-collapse") !== -1
          ? (document.body.className = "portal sidebar-open splash-loaded")
          : (document.body.className = "portal sidebar-mini sidebar-collapse splash-loaded");
      }
    },
    mounted() {
      var self = this;
      if (window.location.hash === "") {
        history.pushState(
          { urlPath: "/#/infoboard/dashboard" },
          "",
          "/#/infoboard/dashboard"
        );
      }
      
      // this.$root.$on("deviceAdjusted", params => {
      //   const globalEventDistributor = new GlobalEventDistributor();
      //   globalEventDistributor.dispatch({ deviceLoaded: true });
      // });

      let dimensionValue = localStorage.user_name.split('@')[1];
      this.$gtag.customMap({ 'dimension1': 'Customer-Dev' });

      this.$gtag.set('page', this.$route.fullPath);
      this.$gtag.pageview(this.$route.fullPath);

      this.$gtag.event('customer_dimension', { 'Customer-Dev': dimensionValue });
      this.$gtag.pageview({ 'dimension1':  dimensionValue });

      let dataLayer = this.$gtm.dataLayer();
      dataLayer.push({
        event: "page_view",
        tenant: dimensionValue,
        group: localStorage.selectedUserGroup
      });

      router.afterEach(( to, from ) => {
        this.$gtag.set('page', to.fullPath);
        this.$gtag.pageview(to.fullPath);

        this.$gtag.event('customer_dimension', { 'Customer-Dev': dimensionValue });
        this.$gtag.pageview({ 'dimension1':  dimensionValue });
      });
    },
    // beforeDestroy() {
    //   //Remove event listeners
    //   this.$root.$off("deviceAdjusted");
    // }
  });
  window.vm = vm;
  notifyLogin(vm);
}

window.toggleMenu = function () {
  document.body.className.indexOf("sidebar-collapse") !== -1
    ? (document.body.className = "portal sidebar-open splash-loaded")
    : (document.body.className = "portal sidebar-mini sidebar-collapse splash-loaded");
};

export function initApp() {
  startSplashScreen();
  //Axios thingy start
  window.axios = require("axios");
  Vue.config.productionTip = false;
  //Make config available to other apps
  saveConfigToLocalStorage();
  //Notify that I'm logged in
  //notifyLogin();
  //Axios thingy end
  initFeatures();
  //Trigger login route
  //URL: dev-ulogins.klinkplatform.com

  /*
  console.log("Content loading error handlers...");
  window.onerror = function(message, url, line, col, errorObj) {
    console.log(`${message}\n${url}, ${line}:${col}`);
  };
  document.getElementsByClassName("dash-iframe")[0].onerror = function(){
    console.log("IFRAME ERROR DETECTED");
  }
  */
}

function startSplashScreen() {  

  //Create a container for the splash screen
  var splashScreenElement = document.createElement("div");
  splashScreenElement.setAttribute("id", "splashscreen");
  document.querySelectorAll("body")[0].appendChild(splashScreenElement);

  localStorage.setItem('appLoaded', 'false');

  //Vue instantiation for the splashscreen
  var vm = new Vue({
    el: "#splashscreen",
    mixins: [httpMixin],
    render: h => h(SplashScreen),
    components: { SplashScreen },
  });
  window.vm = vm;
}