var httpMixin = {
    beforeCreate: function () {
        if (localStorage.getItem("user_id")) {
            localStorage.setItem("user_id", localStorage.getItem("user_id"));
        }
    },
    methods: {
        sendRequest: function (config) {
            /*
             config object could contain bellow params:
             url - request url - obligatory
             method - request method type - optional | default GET
             body - request body - optional
             headers - request headers - optional | default Content-type and Accept headers
             auth - tells request handler to include Authorization and UserId headers - optional | default false
             notifications - tells request handler
             */
            var options = {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",
                    "Accept": "application/json;charset=UTF-8"
                },
                method: "GET"
            };

            options.url = config.url;
            "method" in config ? options.method = config.method : null;
            "body" in config ? options.data = config.body : null;
            "headers" in config ? options.headers = config.headers : null;

            config.auth ? options.headers.Authorization = "Bearer " + localStorage.getItem("user_token") : null;//Add Authorization header if requested
            config.auth ? options.headers.UserId = localStorage.getItem("user_id") : null;//Add UserId header if requested
            localStorage.getItem("selectedUserGroup") ? options.headers["x-scope-orgid"] = localStorage.getItem("selectedUserGroup") : null; //Add Selected Organization ID 

            //notifications handling
            if ("notification" in config) {
                config.notification.room ? options.headers.NotifRoom = "user_" + localStorage.getItem("user_id") + "_" + config.notification.room : null;
                config.notification.message ? options.headers.NotifMsg = config.notification.message : null;
            }


            return axios(options);
        }
    }
};

export default httpMixin;