import Vue from 'vue'
import Router from 'vue-router'
import PortalMain from '../components/pages/PortalMain.vue'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PortalMain',
      component: PortalMain
    }
  ],
  mode: 'history'
})
