<template id="splash-one">
    <div class="splash-bckg">
        <div class="splash-content">
            <div class="splash-user-image">
                <img :src="userImageSrc" alt="User Full Name" class="img-responsive">
            </div>
            <div>
                <h2 class="splash-user-welcome-message">Welcome {{userDisplayName}}</h2>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'splash-screen-one',
        props: [
            "userDisplayName",
            "userImageSrc"
        ]
    }
</script>