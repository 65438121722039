var config = require("./config.json");
export default {
  allItems: [
    {
      rowKey: "dashboard",
      name: "Overview",
      url: "#/infoboard/dashboard",
      icon: "dashboard",
      category: "root",
      index: 0,
      enabled: true
    },
    {
      rowKey: "dashboards_list",
      name: "Dashboards",
      url: "#/infoboard/dashboardsList",
      icon: "view_agenda",
      category: "shortcut",
      index: 1,
      enabled: false
    },
    {
      rowKey: "devices",
      name: "Devices",
      url: "#/devices/all",
      icon: "edgesensor_high",
      category: "dataIntegration",
      index: 0,
      enabled: false
    },
    {
      rowKey: "datasources",
      name: "Open data",
      url: "#/datasources/public",
      icon: "share",
      category: "dataIntegration",
      index: 1,
      enabled: false
    },
    {
      rowKey: "data_importer",
      name: "Import",
      url: "#/dataimporter/dataimporter",
      icon: "upload_file",
      category: "tools",
      index: 0,
      enabled: false
    },
    {
      rowKey: "devices_import",
      name: "Data import",
      url: "#/devices/import",
      icon: "upload_file",
      category: "tools",
      index: 999,
      enabled: false
    },
    {
      rowKey: "alerting",
      name: "Alerting",
      url: "#/alerting/list",
      icon: "notifications",
      category: "shortcut",
      index: 999,
      enabled: false
    },
    {
      rowKey: "map",
      name: "Maps",
      url: "#/map/overview",
      icon: "map",
      category: "shortcut",
      index: 2,
      enabled: false
    },
    {
      rowKey: "infoboard",
      name: "Infoboard",
      url: "#/infoboard/board",
      icon: "info",
      category: "root",
      index: 999,
      enabled: false
    },
    {
      rowKey: "visualization",
      name: "Visualization",
      url: "#/infoboard/spectator",
      icon: "web_asset",
      category: "shortcut",
      index: 998,
      enabled: false
    },
    {
      rowKey: "statistics",
      name: "Statistics",
      url: "#/statistics/list",
      icon: "analytics",
      category: "shortcut",
      index: 997,
      enabled: false
    },
    {
      rowKey: "lab",
      name: "Analytics",
      url: "",
      icon: "analytics",
      enabled: false,
      category: "shortcut",
      index: 0,
      newTab: true
    },
    {
      rowKey: "settings",
      name: "Settings",
      url: "#/settings/settings",
      icon: "settings",
      category: "tools",
      index: 2,
      enabled: false
    }
  ],
  items: [],
  setDefaults: function() {
    var msg =
      "If you believe you should have an active account, please get in touch with your customer success manager.";
    var self = this;
    self.allItems.forEach(function(item, index) {
      item.enabled ? (self.items[index] = item) : null;
    });

    if (self.items.length === 1) {
      history.pushState({}, "", "/#/infoboard/notification?msg=" + msg);
    } else {
      history.pushState({}, "", "/#/infoboard/dashboard");
    }
  },
  initializeFeatureToggling: function(features) {
    var self = this;
    if (window.location.href.indexOf("#/infoboard/notification") !== -1) {
      history.pushState({}, "", "/#/infoboard/dashboard");
    }
    self.items = [];
    var iframeUrl =
      "http://lab.klinkplatform.com:5601/goto/8bb110be8bebd452dce040633ceeb7fe?embed=true";
    features.forEach(function(item, index) {
      var navItems = [...self.allItems].filter(i => {
        return (
          i.rowKey.toLowerCase() === item.rowKey.toLowerCase() && item.enabled
        );
      });
      if (navItems.length > 0) {
        if (navItems[0].rowKey.toLowerCase() === "visualization") {
          item.iframeUrls ? (iframeUrl = item.iframeUrls) : null;
          navItems[0].url += "?iframeUrl=" + iframeUrl;
        }
        if (
          navItems[0].rowKey.toLowerCase() === "lab" &&
          item.rowKey == "lab" &&
          item.iframeUrls
        ) {
          navItems[0].url = item.iframeUrls;
          navItems[0].enabled = item.enabled;
          config.active_env.url_kibana = item.iframeUrls;
          localStorage.activeAppConfig = JSON.stringify(config.active_env);
        }
        self.items.push(navItems[0]);
      }
    });

    // Map items by category
    let itemsByCategory = self.items.reduce((categoryName, item) => {
      // Check if the category already exists
      if (!categoryName[item.category]) {
        categoryName[item.category] = []; // Initialize the category with an empty array
      }
      // Add the item to its category
      categoryName[item.category].push(item);
      // Sort the category array by index every time a new item is added
      categoryName[item.category].sort((a, b) => a.index - b.index);
      return categoryName;
    }, {});

    self.items = itemsByCategory;
  }
};
