import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
var config = require("./config.json");

// var URL = "http://localhost/messages";
var URL = config.active_env.url_nexus + "messages";
export default {
    install(Vue) {
        console.log("Installing vuejs message hub plugin...")
        var connection = new HubConnectionBuilder()
            .withUrl(URL)
            .configureLogging(LogLevel.Information)
            .build();

        const messageHub = new Vue() // use new Vue instance as an event bus

        // Forward hub events through the event, so we can listen for them in the Vue components
        connection.on('ReceiveMessage', (message) => {
            console.log('Masterfrontend: Recieved data!')
            // console.log(JSON.parse(message));

            //Try JSON.parsing the message to see if it is a message containing JSON data
            try{
                let parsedData = JSON.parse(message);
                //If the data message is actually a normal message in JSON format...
                if(parsedData.data.type === 'notification'){
                    messageHub.$emit('received-message', { message: parsedData.data })
                //Else, emit it as recieved data
                } 
                else if (parsedData.data.type === 'error') {
                    messageHub.$emit('received-error-message', { message: parsedData.data })
                }
                else if (parsedData.data.type === 'warning') {
                    messageHub.$emit('received-warning-message', { message: parsedData.data })
                }
                else if (parsedData.data.type === 'datasource-step') {
                    messageHub.$emit('received-datasource-step', { message: parsedData.data })
                }
                // else if (parsedData.data.type === 'datasource-preview-data') {
                //     messageHub.$emit('received-preview-data', { message: parsedData.data })
                // }
                else{
                    messageHub.$emit('received-data', { message }) //Emit it as data
                }
            }catch(error){
                messageHub.$emit('received-message', { message }) //Else, emit as message
            }
        })

        // Provide methods for components to send messages back to server
        // Make sure no invocation happens until the connection is established
        messageHub.joinGroup = (group) => {
            console.log("Joining to group: " + group);
            return startedPromise
                .then(() => connection.invoke('JoinFromGroup', group))
                .catch(console.error)
        }

        messageHub.leaveGroup = (group) => {
            return startedPromise
                .then(() => connection.invoke('RemoveFromGroup', group))
                .catch(console.error)
        }

        messageHub.sendMessageToGroup = (group, message) => {
            return startedPromise
                .then(() => connection.invoke("SendMessageToGroup", group, message))
                .catch(console.error)
        }

        // Add the hub to the Vue prototype, so every component can listen to events or send new events using this.$messageHub
        Vue.prototype.$messageHub = messageHub
        window.$messageHub = messageHub;
        // You need to call connection.start() to establish the connection
        // the client wont handle reconnecting for you! Docs recommend listening onclose
        // and handling it there. This is the simplest of the strategies
        let startedPromise = null
        function start() {
            startedPromise = connection.start()
                .catch(err => {
                    console.error('Failed to connect with hub', err)
                    return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
                })
            return startedPromise
        }
        
        connection.onclose(() => start().then(() => {
            console.log("SignalR connection successfully re-established");

            messageHub.joinGroup("tenant_" + localStorage.tenant_id);
            messageHub.joinGroup("user_" + localStorage.user_id);
        }))

        start().then(() => {
            console.log("SignalR connection successfully established");

            messageHub.joinGroup("tenant_" + localStorage.tenant_id);
            messageHub.joinGroup("user_" + localStorage.user_id);
        })
    }
}