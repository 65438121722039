<template>
    <aside id="left-panel" class="left-panel main-sidebar">
        <section class="sidebar">
            <div class="paddingContainer">
                <!-- Logo -->
                <a href="#/infoboard/dashboard" v-on:click="setActive('Home')" class="logo">
                    <div id="main-logo"></div>
                </a>
                
                <!-- <hr class="separator addMarginTop"> -->
            </div>

            <!-- Sidebar Menu -->
            <div v-for="category in orderedCategories" :key="category.key">
                <p v-if="category.key !== 'root'" class="categoryInfo"> {{category.displayName}} </p>

                <ul class="sidebar-menu navbar-nav" data-widget="tree">
                    <template v-for="item in navItems[category.key]" >
                        <li class="nav-item" :key="item.rowKey">
                            <a :href="item.url"
                                :id="setItemId(item)"
                                class="nav-link"
                                v-bind:class="{active: isActive(item.name)}"
                                v-on:click="setActive(item.name)"
                                v-if="!item.newTab"
                                >
                                <span class="material-icons-outlined menu-icon">{{ item.icon }}</span>
                                <span class="menu-title-text">{{ item.name }}</span>
                            </a>
                            <a :href="item.url"
                                class="nav-link"
                                v-bind:class="{active: isActive(item.name)}"
                                v-on:click="setActive(item.name)"
                                v-if="item.newTab"
                                target="_blank"
                                >
                                <span class="material-icons-outlined menu-icon">{{ item.icon }}</span>
                                <span class="menu-title-text">{{ item.name }}</span>
                            </a>
                        </li>
                    </template>
                </ul>
            </div>

            <!-- End of sidebar-menu -->
        </section>
    </aside>

</template>

<script>
    export default {
        name: 'sidebar',
        props: {
            navItems: {
                type: Object,
                required: true,
                default: () => {}
            }
        },
        data() {
            return {
                orderedCategories: [
                    { key: 'root', displayName: 'MAIN' },
                    { key: 'dataIntegration', displayName: 'DATA INTEGRATION' },
                    { key: 'shortcut', displayName: 'SHORTCUTS' },
                    { key: 'tools', displayName: 'TOOLS' }
                ],
                activeItem: String
            }
        },
        mounted() {
            window.addEventListener('appLoaded', () => {
                let currentItem = window.location.hash;

                if (currentItem.includes("?"))
                    currentItem = currentItem.split("?")[0];

                let currentItemArea = currentItem.split("/")[1];
                const item = Object.values(this.navItems)
                    .flatMap(items => items)
                    .find(x => x.url.toString().includes(currentItemArea));           
                this.activeItem = item.name;
            });
        },
        computed: {
            setItemId() {
                return (item) => {
                    if (item.name === 'Settings') {
                        return 'settingsMainMenuItem';
                    }
                };
            }
        },
        methods: {           
            isActive: function (menuItem) {
                return this.activeItem === menuItem
            },
            setActive: function (menuItem) {
                this.activeItem = menuItem 
            }
        },
        beforeDestroy() {
            window.removeEventListener('appLoaded', () => {});
        }
    }
</script>
