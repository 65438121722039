<template>
    <header class="main-header">
        <nav class="navbar navbar-static-top child-app-container" role="navigation">
            <div class="d-flex">
                <button id="sidebar-toggle" class="btn btn-default" @click="toggleMenu()">
                    <i class="material-icons-outlined">menu</i>
                </button>
                <div id="child-project-header" :class="routeName + '-cph'"></div>
            </div>

            <div class="navbar-custom-menu">
                <ul class="navbar-nav">
                    <!-- <li class="nav-item input-group mr-3" :class="{'showSearch': showSearch}">
                        <input type="text" class="form-control searchInput" id="searchInput" @keyup.enter="searchNotification">                                     
                        <div class="input-group-prepend" @click="showSearchClick">
                            <div class="input-group-text">
                                <i class="material-icons-outlined buttonIcon">search</i>
                            </div>
                        </div>
                    </li> -->

                    <li class="nav-item dropdown mr-3">
                        <div class="nav-link dropdown-toggle btn" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="material-icons-outlined buttonIcon" :class="{'notifIconActive': notifications.length > 0}">notifications</i>
                            <div class="notificationCircle" v-if="notifications.length > 0"></div>    
                        </div>
                        <ul id="dropdown2" class="dropdown-menu dropdown-menu-right pointerCursor">
                            <li class="dropdown-item pointer-events-remove">
                                <span class="dropdown-item-text dropdown-item-text-bold p-0">You have {{notifications ? notifications.length : 0}} notification(s)</span>                               
                            </li>
                            <li v-for="(notif, index) in notifications" :key="index" class="dropdown-item " @click="clearNotification(index)" @click.stop="stopTheEvent">
                                <i v-if="notif.type === 'success'" class="material-icons-outlined buttonIcon successIcon">check_circle</i> 
                                <i v-else-if="notif.type === 'warning'" class="material-icons-outlined buttonIcon warningIcon">error_outline</i>
                                <i v-else-if="notif.type === 'info'" class="material-icons-outlined buttonIcon infoIcon">info</i>
                                <i v-else-if="notif.type === 'error'" class="material-icons-outlined buttonIcon errorIcon">cancel</i>
                                <span class="dropdown-item-text"> {{notif.message}} </span>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown mr-3">
                        <div class="nav-link dropdown-toggle btn" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="material-icons-outlined buttonIcon">dark_mode</i>
                        </div>
                        <ul id="dropdown3" class="dropdown-menu dropdown-menu-right">
                            <li class="dropdown-item" @click="setLightTheme" :class="{active: theme == 'light'}">
                                <KlinkRadioButton value="light" name="theme" label="Light theme" v-model="selectedTheme" :checked="selectedTheme === 'light'"/>
                            </li>
                            <li class="dropdown-item" @click="setDarkTheme" :class="{active: theme == 'dark'}">
                                <KlinkRadioButton value="dark" name="theme" label="Dark theme" v-model="selectedTheme" :checked="selectedTheme === 'dark'"/>
                            </li>
                        </ul>
                    </li>

                    <div class="nav-item dropdown user-panel">
                        <div class="nav-link dropdown-toggle btn p-0" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img :src="userImageSrc" class="img-responsive rounded-circle sidebar-avatar" alt="user" />                 
                        </div>
                        <ul id="dropdown4" class="dropdown-menu dropdown-menu-right profile-dropdown">
                            <li class="dropdown-item userOrganizationItem" @click.stop="stopTheEvent">
                                <div class="user-info">
                                    <p class="user-full-name mb-0"> {{userDisplayName}} </p>
                                    <p class="user-organization-name mb-0"> {{userTenantName}} </p>
                                    <!-- <div class="d-flex" @click="openExpander">
                                        <p class="user-organization mb-0"> {{ selectedUserGroupName }} </p> //bilo komentarisano data-toggle="collapse" data-target="#collapseOrganization" aria-expanded="false" aria-controls="collapseOrganization"
                                        <i class="material-icons-outlined user-organization-icon" v-if="!showOrganization">arrow_drop_down</i>
                                        <i class="material-icons-outlined user-organization-icon" v-else >arrow_drop_up</i>
                                    </div> -->
                                </div>

                                <!-- <div class="collapse user-info-content" :class="{'showOrganization': showOrganization}">
                                    <div>
                                        <div class="d-flex flex-row justify-content-between align-items-center" 
                                            v-for="(group) in userGroups" :key="group.name">
                                            <KlinkRadioButton type="checkCircle" class="my-2" name="userGroup" :value="group.value" :label="group.name" v-model="selectedUserGroup" :checked="selectedUserGroup === group.value" />
                                            <KlinkStarFavorite class="mt-2" :isFavorite="group.isFavorite" @favoriteChanged="toggleFavorite(group)"/>
                                        </div>
                                    </div>
                                </div> -->
                            </li>
                            <li class="dropdown-item" @click="toggleUserId()">
                                <i class="material-icons-outlined dropdown-icon">vpn_key</i>
                                <span class="dropdown-item-text">My User ID</span>          
                            </li>
                            <li class="dropdown-item" @click="toggleToken()">
                                <i class="material-icons-outlined dropdown-icon">vpn_key</i>
                                <span class="dropdown-item-text">My API Key</span>          
                            </li>
                            <li class="dropdown-item" @click="logout()">
                                <i class="material-icons-outlined dropdown-icon">logout</i>
                                <span class="dropdown-item-text">Log out</span>
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>
        </nav>

        <KlinkModal title="API Key" modal_id="apiKeyModal" modalSize="lg">
            <template slot="body">
                <p class="modalContent"> {{ userToken }} </p>
                <input type="hidden" id="testing-code" :value="userToken">
            </template>

            <template slot="footer">
                <KlinkButtonNormal label="Copy" @normal-button-clicked="copyTokenToClipboard"/>
            </template>
        </KlinkModal>

        <KlinkModal title="My User ID" modal_id="userIdModal" modalSize="md">
            <template slot="body">
                <p class="modalContent"> {{ userId }} </p>
                <input type="hidden" id="testing-code-user-id" :value="userId">
            </template>

            <template slot="footer">
                <KlinkButtonNormal label="Copy" @normal-button-clicked="copyUserIdToClipboard"/>
            </template>
        </KlinkModal>
    </header>
    
</template>

<script>
    import { KlinkRadioButton,  KlinkModal, KlinkButtonNormal, KlinkStarFavorite } from 'klink-frontend-toolkit-vue';

    export default {
        name: "my-header",
        components: {
            KlinkRadioButton,
            KlinkModal,
            KlinkButtonNormal,
            KlinkStarFavorite
        },
        props: {
            notifications: Array, 
            notifNum: Number,
            userTenantName: String,
            userDisplayName: String,
            userImageSrc: String,
            theme: String            
        },
        data() {
            return {   
                routeName: "",           
                userToken: String,
                userId: String,
                showSearch: false,
                showOrganization: false,
                interval: null,
                userGroups: [],
                selectedUserGroup: null,
                selectedUserGroupName: null
            }
        },
        computed: {
            selectedTheme() {
                return this.theme;
            }
        },
        methods: {
            initUserGroups() {                
                var activeConfig = JSON.parse(localStorage.activeAppConfig);
                var options = {
                    headers: {
                        "Content-type": "application/json;charset=UTF-8",
                        Accept: "application/json;charset=UTF-8"
                    },
                    method: "GET",
                    url: `${activeConfig.url_directory}UserGroup`,
                    auth: true
                };

                vm.sendRequest(options)
                .then((response) => {
                    let responseData = response.data;
                    
                    if (localStorage.getItem("storedUserGroups") === null)
                        localStorage.setItem("storedUserGroups", JSON.stringify(responseData));

                    this.loadUserGroups();
                })
                .catch((error) => {
                    console.log(error);
                });
            },
            loadUserGroups() {
                let storedUserGroups = JSON.parse(localStorage.storedUserGroups);
                storedUserGroups.forEach(el => {
                    this.userGroups.push({ name: `${el.displayName}`, value: `${el.gid}`, isFavorite: false });
                });

                this.selectedUserGroup = this.userGroups[0].value;
                localStorage.setItem("selectedUserGroup", this.selectedUserGroup);
                this.selectedUserGroupName = this.userGroups.find(x => x.value === this.selectedUserGroup).name;

                this.userGroups[0].isFavorite = true;
                this.sortByFavorites();

                if (localStorage.getItem("userGroups") === null)
                    localStorage.setItem("userGroups", JSON.stringify(this.userGroups));
                else
                    this.userGroups = JSON.parse(localStorage.userGroups);
            },
            toggleMenu() {
                window.toggleMenu();
            },
            setDarkTheme() {
                this.$emit("setDarkThemeEmit");
            },
            setLightTheme() {
                this.$emit("setLightThemeEmit");
            },
            clearNotification(index) {
                this.$root.$emit("removeNotification", index);
            },
            logout() {
                window.userAgentApplication.logout();
            },
            toggleToken(){
                this.loadToken();

                this.$root.$emit('apiKeyModal_open');
            },
            loadToken(){
                this.userToken = "Bearer " + localStorage.user_token;
            },
            copyTokenToClipboard(){ 
                var testingCodeToCopy = document.querySelector("#testing-code");
                testingCodeToCopy.setAttribute('type', 'text')
                testingCodeToCopy.select()
                document.execCommand('copy');
                testingCodeToCopy.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()

                this.$root.$emit("fireNotification", {
                    type: "success",
                    text: "Token copied!"
                });
                this.$root.$emit('modal_close');
            },
            toggleUserId() {
                this.userId = localStorage.user_id;

                this.$root.$emit('userIdModal_open');
            },
            copyUserIdToClipboard() {
                var testingCodeToCopy = document.querySelector("#testing-code-user-id");
                testingCodeToCopy.setAttribute('type', 'text')
                testingCodeToCopy.select()
                document.execCommand('copy');
                testingCodeToCopy.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()

                this.$root.$emit("fireNotification", {
                    type: "success",
                    text: "User ID copied!"
                });
                this.$root.$emit('modal_close');
            },
            showSearchClick() {
                this.showSearch = !this.showSearch;
            },
            stopTheEvent(event) {
                event.stopPropagation();
            },
            openExpander() {
                this.showOrganization = !this.showOrganization;
            },
            toggleFavorite(selectedGroup) {
                this.userGroups.forEach(group => {
                    if (group.name === selectedGroup.name) 
                        group.isFavorite = !group.isFavorite;
                });

                this.sortByFavorites();

                if (localStorage.getItem("userGroups") != null)
                    localStorage.setItem("userGroups", JSON.stringify(this.userGroups));
            },
            sortByFavorites() {
                this.userGroups.sort((x, y) => {
                    return (x.isFavorite === y.isFavorite) ? 0 : x.isFavorite ? -1 : 1;
                });  
            },
            searchNotification() {
                this.$root.$emit("fireNotification", {
                    type: "info",
                    text: "Search is not currently available... Coming soon!"
                });
            }
        },
        created(){
            //Init child project route name
            this.routeName = this.$route.hash.split('/')[1] ? this.$route.hash.split('/')[1] : 'd-none ';
        },
        mounted() {
            // this.initUserGroups();
            // this.interval = setInterval(() => {
            //     this.initUserGroups();
            // }, 900000);

            // Instead of loading list of groups and enable selection I am only hardocding this value to default tid
            // When we want to use the delete the following hardcoded line and uncomment the code above
            let defaultTid = localStorage.getItem('tenant_id');
            localStorage.setItem("selectedUserGroup", defaultTid);

            window.addEventListener('appLoaded', () => {        
                let currentItem = window.location.hash;

                if (currentItem.includes("?"))
                    currentItem = currentItem.split("?")[0];

                this.routeName = currentItem.split('/')[1] ? currentItem.split('/')[1] : 'd-none ';
            });
        },
        beforeDestroy() {
            clearInterval(this.interval);
            window.removeEventListener('appLoaded', () => {});
        },
        watch: {
            selectedUserGroup(newVal){
                this.selectedUserGroup = newVal;
                localStorage.setItem("selectedUserGroup", this.selectedUserGroup);
                this.selectedUserGroupName = this.userGroups.find(x => x.value === this.selectedUserGroup).name;
                this.showOrganization = false;
            },
            $route(newVal){
                this.routeName = newVal.hash.split('/')[1] ? newVal.hash.split('/')[1] : 'd-none ';
            }
        }
    }
</script>