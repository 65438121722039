<template>
    <div class="app sidebar-mini">
        <KlinkNotification />
        <div class="app-side" v-if="appLoaded">
            <Sidebar :nav-items="navItems" />
            <Header :notifications="notifications" :notifNum="notifNum" :userTenantName="userTenantName" :user-display-name="userDisplayName" :user-image-src="userImageSrc" :theme="preferedTheme"
                @setLightThemeEmit="setLightTheme" @setDarkThemeEmit="setDarkTheme" />
        </div>
  </div>
</template>

<script>
    import Vue from "vue"; 
    import nav from "../../helpers/nav";
    import Sidebar from "../widgets/Sidebar.vue";
    import Header from "../widgets/Header.vue";
    import MessageHub from "../../helpers/message-hub.js";
    import { KlinkNotification } from 'klink-frontend-toolkit-vue';
    var config = require("../../helpers/config.json");

    Vue.use(MessageHub);

    export default {
        data() {
            return {
                preferedTheme: '', //When this property is empty, the theme is set to the default theme - light mode!
                companyAvatar: require("@/assets/img/Logoanimasjon_loop.gif"),
                userTenantName: "",
                userDisplayName: "",
                userImageSrc: "",
                navItems: nav.items,     
                loading: false,
                notifications: [],
                notifNum: 0,
                showEsErrMsg: false,
                get appLoaded() {
                    return localStorage.getItem('appLoaded');
                },
            };
        },
        components: {
            Header,
            Sidebar,
            KlinkNotification
        },
        methods: {
            // checkNotifMsg: function(message) {
            //     return (
            //         message === "Device succesfully updated" ||
            //         message === "Device succesfully created" ||
            //         message === "Device succesfully removed"
            //     );
            // },
            setDarkTheme() {
                this.preferedTheme =  'dark'; //set theme value
                document.documentElement.setAttribute('data-theme', this.preferedTheme); // updates the data-theme attribute
                localStorage.setItem('theme', this.preferedTheme); // stores theme value in local storage

                let event = new Event("appThemeChanged");
                window.dispatchEvent(event);
            },
            setLightTheme() {
                this.preferedTheme =  'light'; //set theme value
                document.documentElement.setAttribute('data-theme', this.preferedTheme); // updates the data-theme attribute
                localStorage.setItem('theme', this.preferedTheme); // stores theme value in local storage

                let event = new Event("appThemeChanged");
                window.dispatchEvent(event);
            },
            setNotif: function() {
                var self = this;
                setTimeout(function() {

                    // window.$messageHub.joinGroup("tenant_" + localStorage.tenant_id);
                    // window.$messageHub.joinGroup("user_" + localStorage.user_id);

                    window.$messageHub.$on("received-message", function(data) {
                        self.fireNotif("success", data.message.message);
                        if (data.message) {
                            self.notifications.push({type: "success", message: data.message.message});
                            self.notifNum = self.notifNum + 1;

                            if (Object.keys(data.message.payload).length > 0) {
                                let event;

                                switch (data.message.payload.sourceName) {
                                    case "IoTServices":
                                        event = new Event("refreshServiceListCustom");
                                        break;

                                    case "ScadaOpc":
                                        event = new Event("refreshServerListCustom");
                                        break;

                                    case "Devices_iot":
                                        event = new Event("refreshDeviceCustom");
                                        break;

                                    case "Devices_scada":
                                        event = new Event("refreshDeviceCustom");
                                        break;      
                                                                
                                    case "Devices_":
                                        event = new Event("refreshDeviceCustom");
                                        break;

                                    case "DeviceFilters": 
                                        event = new Event("refreshFilterListCustom");
                                        break;

                                    case "DataSources_Registry":
                                        event = new CustomEvent("refreshPublicDataSourceListCustom", { detail: data.message.payload.redirectUrl ? data.message.payload.redirectUrl : null });
                                        break;

                                    case "MetadataManagement":
                                        event = new Event("refreshCustomMetadataItems");
                                        break;

                                    default:
                                        break;
                                }

                                if (typeof event !== "undefined")
                                    window.dispatchEvent(event);
                            }    
                            
                            // var event;
                            // if (typeof Event === "function") {
                            //     event = new Event("deviceAdjusted");
                            // } else {
                            //     event.initEvent("deviceAdjusted", true, true);
                            // }
                            // window.dispatchEvent(event);  
                        }
                    });

                    window.$messageHub.$on("received-error-message", function(data) {
                        self.fireNotif("error", data.message.message);
                        if (data.message) {
                            self.notifications.push({type: "error", message: data.message.message});
                            self.notifNum = self.notifNum + 1;                                                       
                        }
                    });

                    window.$messageHub.$on("received-warning-message", function(data) {
                        self.fireNotif("warning", data.message.message);
                        if (data.message) {
                            self.notifications.push({type: "warning", message: data.message.message});
                            self.notifNum = self.notifNum + 1;                                                       
                        }
                    });
                }, 3000);
            },
            fireNotif: function(type, text) {
                this.$root.$emit("fireNotification", { type: type, text: text });
            }
        },
        mounted() {
            var self = this;
            this.userTenantName = localStorage.getItem('tenant_name') ? localStorage.getItem('tenant_name') : "Tenant unknown!" ;
            this.userDisplayName = localStorage.getItem('user_display_name');
            this.userImageSrc = localStorage.getItem('user_image');

            this.preferedTheme = localStorage.getItem('theme') || 'light'; //gets stored theme value if any
            document.documentElement.setAttribute('data-theme', this.preferedTheme); // updates the data-theme attribute
            
            this.setNotif();

            this.$root.$on("removeNotification", (index) => {
                if (index > -1) 
                    self.notifications.splice(index, 1);
                self.notifNum = self.notifications.length;
            });
        },
        beforeDestroy() {
            //Remove event listeners
            window.$messageHub.$off("received-message");
            window.$messageHub.$off("received-error-message");
            window.$messageHub.$off("received-warning-message");
            this.$root.$off("removeNotification");

            window.$messageHub.leaveGroup("tenant_" + localStorage.tenant_id);
            window.$messageHub.leaveGroup("user_" + localStorage.user_id);
        }
    }
</script>