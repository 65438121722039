<template id="splash-two">
    <div class="splash-bckg">
        <div class="splash-content">
            <div class="splash-infotiles-logo">
                <img :src="companyAvatar" alt="InfoTiles" class="img-responsive">
            </div>
            <div>
                <h2 class="splash-user-welcome-message splash-message-two">Making sense of {{userDisplayName}}'s data</h2>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'splash-screen-two',
        props: [
            "userDisplayName",
            "companyAvatar"
        ]
    }
</script>